import { Link } from "gatsby"
import React, { useContext } from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Button, Span, Text } from "../../components/Core"
import GlobalContext from "../../context/GlobalContext"

const StyledBox = styled(Box)`
  width: 100%;
`
const HighlightedText = styled(Span)`
  font-weight: 600;
  color: ${({ theme, gContext }) =>
    gContext.isInteractuar ? theme.colors.navyBlue : theme.colors.green};
`

const HoverStyled = styled(Button)`
  &:hover span {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.textHover};
        transition-timing-function: ease-in-out !important;
        -webkit-transition-timing-function: ease-in-out !important;
        transition-duration: 300ms;
      }
    }
  }
`

const Subtitle = () => {
  const gContext = useContext(GlobalContext)

  return (
    <Container>
      <Row>
        <Col>
          <StyledBox>
            <Text
              mb={[3, 2, 3, 4]}
              fontSize={["1.5rem", "1.5rem", "1.7rem", "1.8rem", "2.2rem"]}
              color="primary"
              className={"text-center"}
              opacity={0.9}
              fontWeight={"600"}
              lineHeight={["35px", "50px"]}
            >
              Entonces, ¿vamos a{" "}
              <HighlightedText gContext={gContext}>cambiar</HighlightedText> el
              modo en que trabajamos los independientes?
            </Text>
            <Row xs={12} css="display:flex; justify-content:center;">
              <Link
                to={`${process.env.GATSBY_AIO_APP_URL
                  }/signup${gContext.isInteractuar ? "?is_from_interactuar=true" : ""
                  }`}
                className="link"
              >
                <HoverStyled
                  mt={3}
                  mb={6}
                  variant="factcilSolidSecondary"
                  className="link-button"
                >
                  Sí, ¡vamos! &nbsp;
                  <span>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        verticalAlign: "-1px",
                      }}
                    >
                      <path
                        d="M9.129 5.22859L5.106 1.09077L6.1665 -2.69546e-07L12 6L6.1665 12L5.106 10.9092L9.129 6.77141L-2.28549e-07 6.77141L-2.95988e-07 5.22859L9.129 5.22859Z"
                        fill="#002432"
                      />
                    </svg>
                  </span>
                </HoverStyled>
              </Link>
            </Row>
          </StyledBox>
        </Col>
      </Row>
    </Container>
  )
}

export default Subtitle
