import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Section, Text, Title } from "../../components/Core"
import Bell from "../../images/svg/bell.svg"
import Digital from "../../images/svg/digital.svg"

const SectionStyled = styled(Section)``

const FaqCard = styled(Box)`
  padding-left: 55px;
  position: relative;
  &::before {
    content: "";
    font-weight: 900;
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    font-size: 15px;
    background-image: url(${Digital});
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${({ theme }) => theme.colors.linebreak};
    color: ${({ theme }) => theme.colors.textHover};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    position: absolute;
    left: 0;
  }
`

const FaqSubCard = styled(Box)`
  padding-left: 55px;
  position: relative;
  &::before {
    content: "";
    font-weight: 900;
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    font-size: 15px;
    background-image: url(${Bell});
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${({ theme }) => theme.colors.linebreak};
    color: ${({ theme }) => theme.colors.textHover};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    position: absolute;
    left: 0;
  }
`
const Lister = styled(Box)`
  padding-left: 55px;
  color: white;
  position: relative;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.primary};
  &::before {
    content: "";
    font-weight: 900;
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    font-size: 15px;
    background-image: url(${Bell});
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${({ theme }) => theme.colors.linebreak};
    color: ${({ theme }) => theme.colors.textHover};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    position: absolute;
    left: 0;
  }
`

const FaqTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 24px;
  font-weight: 700;
  opacity: 0.9;
  letter-spacing: -0.75px;
  margin-bottom: 24px;
  line-height: 2rem;
`

const FaqSubTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.75px;
  margin-bottom: 24px;
  line-height: 2rem;
`

const Faq = () => (
  <>
    {/* <!-- Faq section --> */}
    <SectionStyled bg="transparent" css={{ paddingBottom: "3rem" }}>
      <Container>
        <Text
          mb={[3, 2, 4, 5]}
          fontSize={["1.5rem", "1.5rem", "2.5rem", "2.5rem"]}
          color="primary"
          className={"text-center"}
          opacity={0.9}
          fontWeight={"700"}
          lineHeight={["35px", "50px"]}
        >
          Preguntas Frecuentes
        </Text>
        <Row>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Qué servicios ofrece Factcil S.A.S.?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Si eres trabajador independiente o freelancer, puedes acceder
                  a nuestros beneficios de: facturación, gestión administrativa,
                  gestión de cobranza y si lo deseas, anticipar el pago de tus
                  servicios prestados a diferentes empresas.
                </Text>
                <Text
                  variant="small"
                  color="primary"
                  style={{ marginBottom: "20px" }}
                >
                  Prestamos dos tipos de servicios:
                </Text>
                <Row>
                  <Col lg="6" className="mb-5">
                    <FaqSubCard>
                      <div>
                        <FaqSubTitle>Administración de cartera</FaqSubTitle>
                        <Text
                          variant="small"
                          color="primary"
                          textAlign="justify"
                        >
                          Te ayudamos con toda la gestión administrativa, lo que
                          significa registrarnos como proveedor, radicar la
                          factura correspondiente por tus servicios prestados y
                          gestionar la cobranza con nuestros expertos en
                          cartera. Esta solución es perfecta para personas que
                          no cuentan con el tiempo para realizar todo el papeleo
                          correspondiente en una empresa y se quieren librar de
                          dicha tarea.
                        </Text>
                      </div>
                    </FaqSubCard>
                  </Col>
                  <Col lg="6" className="mb-5">
                    <FaqSubCard>
                      <div>
                        <FaqSubTitle>Anticipo</FaqSubTitle>
                        <Text
                          variant="small"
                          color="primary"
                          textAlign="justify"
                        >
                          Además de administrar tu cartera, te anticipamos el
                          valor de tu cuenta de cobro, para que no debas esperar
                          que se cumpla el plazo pactado con la empresa
                          contratante.
                        </Text>
                      </div>
                    </FaqSubCard>
                  </Col>
                </Row>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Cuáles son los beneficios de acceder a los servicios
                  ofrecidos por Factcil S.A.S.?
                </FaqTitle>
                <Row>
                  <Col lg="4" className="mb-5">
                    <FaqSubCard>
                      <div>
                        <FaqSubTitle>Administración de cartera</FaqSubTitle>
                        <Text
                          variant="small"
                          color="primary"
                          textAlign="justify"
                        >
                          Ya que nosotros realizamos la gestión de cobranza ante
                          la empresa pagadora.
                        </Text>
                      </div>
                    </FaqSubCard>
                  </Col>
                  <Col lg="4" className="mb-5">
                    <FaqSubCard>
                      <div>
                        <FaqSubTitle>Gestión de trámites</FaqSubTitle>
                        <Text
                          variant="small"
                          color="primary"
                          textAlign="justify"
                        >
                          Gestionamos los trámites administrativos que deban
                          realizarse ante la empresa contratante o pagadora,
                          porque al aceptar nuestros servicios nos inscribimos
                          como proveedores y realizamos la facturación
                          correspondiente a tu nombre.
                        </Text>
                      </div>
                    </FaqSubCard>
                  </Col>
                  <Col lg="4" className="mb-5">
                    <FaqSubCard>
                      <div>
                        <FaqSubTitle>Liquidez inmediata</FaqSubTitle>
                        <Text
                          variant="small"
                          color="primary"
                          textAlign="justify"
                        >
                          Si lo solicitas, anticipamos el pago de tus servicios
                          sin que tengas que esperar el cumplimiento del plazo
                          de pago pactado con la empresa contratante (30, 45, 60
                          días o más).
                        </Text>
                      </div>
                    </FaqSubCard>
                  </Col>
                </Row>
              </div>
            </FaqCard>
          </Col>
          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Quiénes pueden acceder a los servicios de Factcil S.A.S.?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Factcil S.A.S. presta sus servicios a trabajadores
                  independientes o freelancers como personas naturales o
                  personas Jurídicas, legalmente constituidas. Recuerda que si
                  eres extranjero debes cumplir con los requisitos del
                  Ministerio de Trabajo.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Debo ser mayor de edad para acceder a los servicios de
                  Factcil?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Sí.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Cómo accedo a los servicios de Factcil?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  A través de nuestra{" "}
                  <Link
                    to={"https://factcil.com/"}
                    css="color: #0080B2!important;"
                    style={{
                      borderRadius: "2px",
                      marginLeft: "1px",
                      marginRight: "1px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    plataforma
                  </Link>{" "}
                  puedes registrarte y solicitar el anticipo o administración de
                  tus cuentas de cobro.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Qué documentos necesito para hacer una solicitud?
                </FaqTitle>
                <Row>
                  <Col lg="6" className="mb-5">
                    <FaqCard>
                      <div>
                        <FaqSubTitle>
                          Debes tener a la mano la siguiente información de tu
                          cuenta de cobro:
                        </FaqSubTitle>
                        <Lister>Valor del servicio prestado</Lister>
                        <Lister>
                          Razón social y NIT de la empresa que te paga
                        </Lister>
                        <Lister>
                          Plazo de pago (número de días en los que la empresa va
                          a pagar)
                        </Lister>
                      </div>
                    </FaqCard>
                  </Col>
                  <Col lg="6" className="mb-5">
                    <FaqCard>
                      <div>
                        <FaqSubTitle>
                          Debes tener a la mano los siguientes documentos
                          personales:
                        </FaqSubTitle>
                        <Lister>
                          Copia del documento de identidad por ambas caras
                        </Lister>
                        <Lister>RUT actualizado al presente año</Lister>
                        <Lister>Certificado bancario</Lister>
                      </div>
                    </FaqCard>
                  </Col>
                </Row>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  Si accedo a los servicios ofrecidos por Factcil S.A.S.,
                  ¿cuánto tiempo se demora el análisis y respuesta de mi
                  solicitud?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  El tiempo de análisis y respuesta a tu solicitud es de 48
                  horas.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  Si acepto el pago anticipado de mis servicios, ¿cuánto tiempo
                  se demora Factcil S.A.S. en desembolsarme el dinero?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Una vez que la empresa pagadora nos acepte la factura de tus
                  servicios, el desembolso se verá reflejado en la cuenta
                  bancaria que nos hayas suministrado, en un plazo máximo de 24
                  horas para días hábiles y de 48 horas los fines de semana y
                  festivos. Se debe tener en cuenta el tiempo de ejecución de la
                  transacción interbancaria.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Qué características debe tener una cuenta de cobro para que
                  sea aceptada por Factcil?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Para que la compra de la cuenta de cobro sea válida, esta no
                  puede estar vencida ni radicada; es decir, no aceptamos
                  cuentas de cobro previamente radicadas y que la empresa no ha
                  pagado.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Qué pasa si ya radiqué mi cuenta de cobro?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Debes solicitar a la empresa contratante o pagadora que genere
                  una nueva Orden de Compra u Orden de Servicio a nombre de
                  Factcil S.A.S., de lo contrario esta no podrá ser aceptada.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Cuánto cobra Factcil S.A.S. por sus servicios?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Una vez que la empresa pagadora nos acepte la factura de tus
                  servicios, el desembolso se verá reflejado en la cuenta
                  bancaria que nos hayas suministrado, en un plazo máximo de 24
                  horas para días hábiles y de 48 horas los fines de semana y
                  festivos. Se debe tener en cuenta el tiempo de ejecución de la
                  transacción interbancaria.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Desde qué valor puedo solicitar los servicios de Factcil
                  S.A.S.?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Aceptamos cuentas de cobro desde $150.000 COP.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Cuántas veces puedo solicitar un anticipo con Factcil S.A.S.?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  En Factcil S.A.S. no otorgamos créditos a nuestros clientes,
                  razón por la cual puedes solicitar todos los anticipos que
                  necesites de tus cuentas de cobro.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Desde qué valor puedo solicitar los servicios de Factcil
                  S.A.S.?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Aceptamos cuentas de cobro desde $150.000 COP.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Puedo hacer mi solicitud si no tengo Orden de Compra o cuenta
                  de cobro?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Si la empresa pagadora no genera Orden de compra u Orden de
                  servicio no hay problema. Es importante que ellos sepan que la
                  facturación de tus servicios se realizará a través de Factcil
                  S.A.S.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Qué debo hacer si no tengo contrato con la empresa pagadora?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Si no tienes contrato con la empresa pagadora no te preocupes.
                  Nosotros nos encargamos de confirmar la información
                  relacionada a tus servicios prestados.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Qué pasa si aún no he entregado el producto o servicio por el
                  cual fui contratado?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Factcil S.A.S. sólo desembolsa el dinero cuando la empresa
                  pagadora recibe el producto/servicio a satisfacción.
                  Normalmente las empresas no dejan facturar hasta que el
                  servicio esté prestado, y a partir de ahí es que comienzan a
                  contar el plazo de pago.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿A qué se refiere que el producto o servicio tenga garantía?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Es cuando prestas un servicio o entregas un producto que tiene
                  “servicio posventa”, es decir, que después de entregado debe
                  cumplir con cierto plazo de satisfacción.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Factcil S.A.S. está vigilada por entes de control?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Sí, estamos legalmente constituidos y somos vigilados por
                  Superintendencia de Sociedades.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Qué pasa si estoy reportado en Centrales de Riesgo?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Las personas reportadas en centrales de riesgo pueden
                  solicitar los servicios de Factcil S.A.S. sin ningún problema.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Para qué usa Factcil S.A.S. la información personal y los
                  documentos que solicita?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Puedes averiguar la política de tratamientos personales y
                  privacidad haciendo clic{" "}
                  <Link
                    to={"/privacy-policy"}
                    style={{
                      backgroundColor: "#7673ba",
                      borderRadius: "2px",
                      marginLeft: "1px",
                      marginRight: "1px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    aquí
                  </Link>{" "}
                  .
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Qué o quién es la empresa pagadora?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Es la empresa que te contrató, a la cual le prestaste tus
                  servicios y la responsable de hacerte el pago de los mismos.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Qué deducciones se realizan sobre el valor de mi cuenta de
                  cobro?
                </FaqTitle>
                <Text
                  variant="small"
                  color="light"
                  textAlign="justify"
                  style={{ marginBottom: "20px" }}
                >
                  Para hacer el cálculo del valor a desembolsar, debes tener en
                  cuenta que se pueden realizar las siguientes deducciones:
                </Text>
                <Row>
                  <Col lg="4" className="mb-5">
                    <FaqSubCard>
                      <div>
                        <FaqSubTitle>Retefuente</FaqSubTitle>
                        <Text
                          variant="small"
                          color="primary"
                          textAlign="justify"
                        >
                          Es un mecanismo que usa el Estado para recaudar
                          anticipadamente el Impuesto de Renta. Es aplicado por
                          la empresa pagadora o contratante.
                        </Text>
                      </div>
                    </FaqSubCard>
                  </Col>
                  <Col lg="4" className="mb-5">
                    <FaqSubCard>
                      <div>
                        <FaqSubTitle>Reteica</FaqSubTitle>
                        <Text
                          variant="small"
                          color="primary"
                          textAlign="justify"
                        >
                          Es un mecanismo utilizado por los municipios, para
                          recaudar anticipadamente el impuesto de Industria y
                          Comercio, que grava las actividades comerciales,
                          industriales y de servicios que se desarrollan en cada
                          municipio o ciudad. Es aplicado por la empresa
                          pagadora o contratante.
                        </Text>
                      </div>
                    </FaqSubCard>
                  </Col>
                  <Col lg="4" className="mb-5">
                    <FaqSubCard>
                      <div>
                        <FaqSubTitle>Reteiva(Si aplica)</FaqSubTitle>
                        <Text
                          variant="small"
                          color="primary"
                          textAlign="justify"
                        >
                          Es aplicado por los grandes contribuyentes al
                          proveedor, si el producto o servicio está gravado con
                          IVA y/o cuando es responsable del IVA, caso en el cual
                          la empresa contratante o pagadora cobra y factura el
                          IVA.
                        </Text>
                      </div>
                    </FaqSubCard>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="mb-5">
                    <FaqSubCard>
                      <div>
                        <FaqSubTitle>Remanente</FaqSubTitle>
                        <Text
                          variant="small"
                          color="primary"
                          textAlign="justify"
                        >
                          Solo se aplica cuando el cliente solicita el servicio
                          de anticipo, y corresponde a un porcentaje del valor
                          de la cuenta de cobro. Este valor se destina
                          únicamente para cubrir intereses de mora y gastos de
                          cobranza, en caso de que la empresa pagadora no haga
                          el pago dentro del plazo pactado. Una vez la empresa
                          el pago se realice, la diferencia será devuelta de
                          acuerdo a los días de mora que se hayan presentado. Si
                          la empresa pagadora cumple con el pago dentro del
                          plazo establecido, el remanente será desembolsado en
                          su totalidad al cliente.
                        </Text>
                      </div>
                    </FaqSubCard>
                  </Col>
                  <Col lg="6" className="mb-5">
                    <FaqSubCard>
                      <div>
                        <FaqSubTitle>
                          Costo de administración de cartera
                        </FaqSubTitle>
                        <Text
                          variant="small"
                          color="primary"
                          textAlign="justify"
                        >
                          Es un porcentaje que se aplica sobre el valor de la
                          cuenta de cobro por los servicios de administración de
                          cartera que ofrece Factcil e incluye los costos de GMF
                          y dispersión o costos financieros que pueden aplicar
                          las entidades bancarias a los giros y consignaciones.
                        </Text>
                      </div>
                    </FaqSubCard>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="mb-5">
                    <FaqSubCard>
                      <div>
                        <FaqSubTitle>Reteiva (Si aplica)</FaqSubTitle>
                        <Text
                          variant="small"
                          color="primary"
                          textAlign="justify"
                        >
                          Es aplicado por los grandes contribuyentes al
                          proveedor, si el producto o servicio está gravado con
                          IVA y/o cuando es responsable del IVA, caso en el cual
                          la empresa contratante o pagadora cobra y factura el
                          IVA.
                        </Text>
                      </div>
                    </FaqSubCard>
                  </Col>
                  <Col lg="6" className="mb-5">
                    <FaqSubCard>
                      <div>
                        <FaqSubTitle>IVA del servicio</FaqSubTitle>
                        <Text
                          variant="small"
                          color="primary"
                          textAlign="justify"
                        >
                          Se aplica sobre el valor de la administración de
                          cartera y corresponde a un porcentaje de 19%.
                        </Text>
                      </div>
                    </FaqSubCard>
                  </Col>
                </Row>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Factcil S.A.S. me certifica las retenciones?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Si, las retenciones que aplique la empresa pagadora serán
                  trasladadas a tu nombre.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Qué documentos debo firmar si acepto los servicios ofrecidos
                  por Factcil S.A.S.?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Cada vez que contrates un servicio con Factcil S.A.S. deberás
                  firmar un contrato (sin exclusividad) donde nos autorizas
                  facturar en representación tuya. Estos contratos se hacen por
                  cada cuenta de cobro que deseas administrar o anticipar con
                  Factcil S.A.S.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="12" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle textAlign="justify">
                  ¿Qué puedo hacer si tengo alguna duda, inquietud o deseo
                  comunicarme con Factcil S.A.S.?
                </FaqTitle>
                <Text variant="small" color="primary" textAlign="justify">
                  Puedes comunicarte a través de nuestro chat virtual en la
                  plataforma o escribir un mail a comercial@factcil.com.
                </Text>
              </div>
            </FaqCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
)

export default Faq
