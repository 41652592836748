import React from "react"
import styled from "styled-components"
import PageWrapper from "../components/PageWrapper"
import Seo from "../components/Seo"
import Subtitle from "../sections/howitworks/Subtitle"
import Faqs from "../sections/landing1/Faqs"
import { device } from "../utils"

const StyledPageWrapper = styled.div`
  background: linear-gradient(
    180deg,
    #e7f8ff 0%,
    rgba(231, 248, 255, 0.05) 20%
  );

  @media ${device.md} {
    background: none;
  }
`

const FaqsPage = () => {
  return (
    <>
      <StyledPageWrapper>
        <PageWrapper footerDark headerBlue>
          <Seo title="Preguntas frecuentes" />
          <Faqs />
          <Subtitle />
        </PageWrapper>
      </StyledPageWrapper>
    </>
  )
}
export default FaqsPage
